<template>
  <div class="real-name-success">
    <div class="title">请按照以下指引完成账号实名信息补充</div>
    <div class="steps">
      <RealNameStep :active="3" />
    </div>
    <div class="content">
      <div class="bg"></div>
      <div class="tips">恭喜完成实名信息补充</div>
      <div class="desc">实名信息补充完成，您可以更好使用平台服务</div>
      <button class="start" @click="startUse">开始使用</button>
    </div>
  </div>
</template>

<script>
import RealNameStep from "components/copyright-service/real-name/RealNameStep";
import { checkUserRealName } from "api/copyrightService";
export default {
  name: "RealNameSuccess",
  methods: {
    startUse() {
      checkUserRealName().then((res) => {
        if (res.code == 200) {
          this.$router.push({
            name: "PersonalAlreadyRealName",
            params: {
              realName: res.data.cardName,
            },
          });
        }
      });
    },
  },
  components: {
    RealNameStep,
  },
};
</script>

<style lang="scss" scoped>
.real-name-success {
  .title {
    margin-top: 12px;
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    text-align: center;
  }
  .steps {
    margin: 39px 0 60px;
  }
  .content {
    @include flex-center(column);
    width: 300px;
    margin: 77px auto 0;
    .bg {
      width: 171px;
      height: 141px;
      background: url("~assets/images/real-success-bg.png") no-repeat;
      background-position: 50%;
    }
    .tips {
      font-size: 16px;
      font-weight: bold;
      color: #333333;
    }
    .desc {
      margin-top: 11px;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
    }
    .start {
      @include noselect;
      width: 120px;
      height: 40px;
      margin-top: 30px;
      background: #ff6900;
      border-radius: 20px;
      font-size: 14px;
      font-weight: 500;
      color: #ffffff;
      outline: none;
      border: none;
      cursor: pointer;
    }
  }
}
</style>
